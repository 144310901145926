import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
// import VueQrcodeReader from "vue-qrcode-reader";

require('./assets/sass/main.scss');

// Vue.config.productionTip = false
// Vue.use(VueQrcodeReader)

// Vue.directive('title', {
//   inserted: (el, binding) => document.title = binding.value,
//   update: (el, binding) => document.title = binding.value
// })

const app = createApp(App);
app.use(router);
// app.use(VueQrcodeReader);
app.config.productionTip = false;
app.directive('title', {
      inserted: (el, binding) => document.title = binding.value,
      update: (el, binding) => document.title = binding.value
    })
app.mount('#app');
