<template>
    <section class="hero is-large has-text-centered">
      <div class="hero-head stgreen-background">
        <div class="field" >
            <img src="./../assets/gympassbetterlogoREV.png" alt="Gympass and Better logo" class="responsive-image" style="margin-top: 8px;">
        </div>
      </div>
      <div class="column is-4 is-offset-4">
        <div class="box needs-padding is-border-green has-background-lighter" v-if="askcode">
          <div class="container">
            <div class="container" >
              <div class="field" >
                <div class="control has-text-centered">
                  <p class="subtitle is-5 has-text-grey">Enter your transaction code</p>
                  <input class="input" type="text" v-model="code" placeholder="e.g. 3D4SD873"/>
                </div>
              </div>
              <button class="button has-text-white-ter stred-background is-rounded is-medium m-t-12 calibri font-size-200" @click="access"> Click here to access </button>
            </div>
          </div>
        </div>
        <div class="container" v-if="!askcode">
          <div class="field" >
            <br>
            <p class="title is-3 stblue-foreground calibri font-size-150" v-if="!ershow">Gympass: feel Better with us</p>
            <p class="title is-3 stgreen-foreground calibri font-size-200" v-if="correct && ershow">{{error}}</p>
            <p class="title is-3 stred-foreground calibri font-size-100" v-if="!correct && ershow">{{error}}</p>
          </div>
          <button class="button has-text-white-ter stred-background is-rounded is-medium m-t-25 calibri font-size-200" @click="access" v-if="correct && !ershow"> Click here to access </button>
        </div>
        <div class="container">
          <img :src="require('./../assets/gympass.gif')" class="responsive-image"/>
          <div class="field" >
            <label class="stblue-foreground calibri"> © Copyright 2021 CCTech Ltd | All Rights Reserved </label>
          </div>
        </div>
      </div>
      <div class="modal is-active" v-if="showModal">
          <div class="modal-background"></div>
          <div class="modal-card">
              <header class="modal-card-head">
                <p class="modal-card-title"></p>
                <button class="delete" aria-label="close" @click="closeModal"></button>
              </header>
              <section class="modal-card-body has-text-dark">
                <div class="box needs-padding stred-background" >
                  <div class="container">
                    <div class="is-vcentered is-mobile">
                      <p class="subtitle is-6 has-text-white">Feel Better with Gympass <br> <br> Please hold the phone 8cm/3 inches from the QR</p>
                      <StreamBarcodeReader  @decode="onDecode"></StreamBarcodeReader>
                      <p class="subtitle is-6 has-text-white">Wait for thecamera to focus and it will scan the QR</p>
                    </div>
                  </div>
                </div>
              </section>
          </div>
        </div>
    </section>
    
    </template>
    
    <script>
    import axios from 'axios';
    import { StreamBarcodeReader } from "vue-barcode-reader";
    import gympassBetterLogo from '../assets/gympassbetterlogoREV.png';
    import gympassGif from '../assets/gympass.gif';

    export default {
      components: {
        StreamBarcodeReader
      },
      data(){
        return{
          gympassBetterLogo,
          gympassGif,
          code: '',
          showModal: false,
          // showTileModal: false,
          decoded: '',
          passes: 0,
          correct: true,
          error: '',
          askcode: true,
          ershow: false
        };
      },
      mounted: function(){
        let uri = window.location.search.substring(1); 
        let params = new URLSearchParams(uri);
        let c = params.get("code");
        if(c !== null){
          this.code = params.get("code");
          if(this.code !== ''){
            this.askcode = false;
          }
        }
      },
      methods: {
        access(){
          this.showModal = true;
        },
        closeModal(){
          this.showModal = false;
        },
        closeTileModal(){
          this.showTileModal = false;
        },
        onDecode(decodedString){
          this.decoded = decodedString;
          const header = {
            headers: {token:'345jds95654671!45yer'} 
          }
          let url = "https://mfapi.smarttile.co.uk"
          axios.post(url + '/api/GymPass/GymPassRequest?tag=' + this.decoded + '&code=' + this.code, null, header)
          .then((res)=> {
              if(res.status === 200){
                // this.askcode = false;
                console.log(res.data)
                this.correct = res.data.key;
                this.error = res.data.value;
                this.ershow = true;
              }
              else
              {
                  this.error = "There is a problem with this link, please try again later";
                  this.correct = false;
                  this.ershow = true;
              }
          })
          .catch((error) => {
              console.log(error);
              this.error = "There is a problem with this link, please try again later";
              this.correct = false;
              this.ershow = true;
          });
          this.showModal = false;
        },
      }
    }
    
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    @import url('https://fonts.googleapis.com/css?family=Rubik');
    *{
      font-family: 'Rubik'!important;
    }
    h3 {
      margin: 40px 0 0;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #42b983;
    }
    .responsive-image {
      width: 50%;
      max-width: 75em;
      margin-top: 8px;
    }

    .stblue-background {
      background-color: #080035;
    }
    .stred-background {
      background-color: #FF4438;
      border-color: rgb(31, 30, 30);
      border-width: 2px;
    }
    .stgreen-background {
      background-color: #55a440;
    }
    .stblue-background-light {
      background-color: #40387094;
    }
    .stblue-foreground{
      color: #080035;
    }
    .stred-foreground{
      color: #FF4438;
    }
    .stgreen-foreground{
      color: #55a440;
    }
    .stred-background-light {
      background-color: #f7665c94;
    }
    .calibri {
      font-family: Calibri, 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
      font-weight: normal;
    }
    .font-size-200{
      font-size: 200%;
    }
    .font-size-150{
      font-size: 150%;
    }
    .font-size-100{
      font-size: 100%;
    }
    .calibri-bold{
      font-weight: bold;
    }
    .m-t-25{
      margin: 25px;
    }
    .m-t-12{
      margin: 12px;
    }
    .transparent-back{
      background-color: transparent;
    }
    </style>
    